import React, { useState } from 'react';
import { supabase } from './supabase';
import { useNavigate } from 'react-router-dom';
//import SaveToHomeScreenPrompt from './SaveToHomeScreen'; // Ensure the correct path

const Login = ({ onExit }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const { error } = await supabase.auth.signInWithPassword({ email, password });
      if (error) {
        setError(error.message);
      } else {
        setError(null);
        // Redirect or show success message
      }
    } catch (err) {
      setError('An unexpected error occurred.');
    }
  };

  const handleCreateAccount = async (e) => {
    e.preventDefault();
    try {
      const { error } = await supabase.auth.signUp({ email, password });
      if (error) {
        setError(error.message);
      } else {
        setError(null);
        // Account creation success logic
      }
    } catch (err) {
      setError('An unexpected error occurred.');
    }
  };

  const handleExit = async () => {
    try {
      if (onExit) {
        onExit(); // Call the provided `onExit` function
      } else {
        navigate('/'); // Redirect to the home page
      }
    } catch (err) {
      console.error('Error during exit:', err);
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img alt="TarotMap" src="/favicon.ico" className="mx-auto h-10 w-auto" />
        <h2 className="mt-6 text-center text-l font-semibold tracking-tight text-neutral-700">
          {isCreatingAccount ? 'Create your TarotMap account' : 'Sign in to your TarotMap account'}
        </h2>
      </div>

      <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="bg-white px-8 py-10 shadow-sm sm:rounded-lg sm:px-12">
          <form
            onSubmit={isCreatingAccount ? handleCreateAccount : handleLogin}
            className="space-y-6"
          >
            {error && (
              <div className="text-red-700 text-md font-medium">{error}</div>
            )}
            <div>
              <input
                id="email"
                name="email"
                type="email"
                required
                placeholder="Email address"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="text-sm w-full mb-1 px-3 py-2 border-neutral-300 rounded-lg ring-1 ring-neutral-100 focus:ring-orange-900/30 hover:bg-orange-50/10 focus:border-none"
              />
            </div>
            <div>
              <input
                id="password"
                name="password"
                type="password"
                required
                placeholder="Password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="text-sm w-full px-3 py-2 border-neutral-300 rounded-lg ring-1 ring-neutral-100 focus:ring-orange-900/30 hover:bg-orange-50/10 focus:border-none"
              />
            </div>
            {isCreatingAccount && (
              <div>
                <input
                  id="confirm-password"
                  name="confirm-password"
                  type="password"
                  required
                  placeholder="Confirm password"
                  className="text-sm w-full px-3 py-2 border-neutral-300 rounded-lg ring-1 ring-neutral-100 focus:ring-orange-900/30 hover:bg-orange-50/10 focus:border-none"
                />
              </div>
            )}
            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-full bg-neutral-700 px-3 py-2.5 text-sm/6 font-semibold text-white shadow-xs hover:bg-orange-900/40 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-900"
              >
                {isCreatingAccount ? 'Create account' : 'Sign in'}
              </button>
            </div>
          </form>

          <div className="relative mt-6">
            <div aria-hidden="true" className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-200" />
            </div>
            <div className="relative flex justify-center text-sm font-medium">
              <span className="bg-white px-6 text-neutral-700">
                {isCreatingAccount
                  ? 'Learn about payment options below'
                  : 'New to TarotMap?'}
              </span>
            </div>
          </div>

          <div className="mt-6 w-full">
            {!isCreatingAccount && (
              <button
                onClick={() => setIsCreatingAccount(true)}
                className="flex w-full items-center justify-center rounded-full bg-white px-3 py-3 text-sm font-semibold text-neutral-700 ring-1 shadow ring-orange-900/50 ring-inset hover:bg-orange-900/5 focus-visible:ring-transparent mb-4"
              >
                <span>Create account</span>
              </button>
            )}
            <div>
              <p className="text-sm text-center font-regular text-gray-500">
                Options to save, share & revisit your readings.
              </p>
            </div>
          </div>
        </div>

        <p className="mt-10 text-center text-sm text-gray-500">
          Not ready to join yet?{' '}
          <button
            onClick={() => setIsModalOpen(true)}
            className="font-semibold text-orange-800 hover:text-orange-800"
          >
            Exit here
          </button>
        </p>
      </div>

      {/* Exit Warning Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-10">
          <div className="bg-white rounded-xl p-12 mx-5 shadow-lg text-sm/6 text-center">
            <p className="mb-4 text-sm/6 font-regular text-neutral-700">
              Are you sure you want to exit? Your reading will be lost.
            </p>
            <span className="w-full grid grid-cols-2 justify-center gap-4">
              <button
                onClick={() => setIsModalOpen(false)}
                className="px-3 py-2 rounded-full ring-1 ring-neutral-300 text-neutral-700 text-sm/6 font-regular hover:bg-gray-100"
              >
                Oops, go back
              </button>
              <button
                onClick={handleExit}
                className="px-3 py-2 rounded-full ring-1 ring-red-700/50 text-red-800 text-sm/6 font-regular hover:bg-red-50 focus:bg-red-50"
              >
                Exit reading
              </button>
            </span>
          </div>
        </div>
      )}
      {/* Save to Home Screen Prompt */}
  
    </div>
  );
};

export default Login;