import React, { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { supabase } from './supabase';
import cardDetails from './CardDetails';
import { useLocation, useNavigate } from 'react-router-dom';
import { Select } from '@headlessui/react';

const courtCardImages = {
 King: '/king.svg',
 Queen: '/queen.svg',
 Knight: '/knight.svg',
 Page: '/page.svg',
};


const numberMapping = {
 Ace: "A",
 Two: "2",
 Three: "3",
 Four: "4",
 Five: "5",
 Six: "6",
 Seven: "7",
 Eight: "8",
 Nine: "9",
 Ten: "10",
};


const checkUserAuthentication = async () => {
  const { data: { user } } = await supabase.auth.getUser();
  return user; // Returns `null` if no user is logged in
};


const ReadingSession = () => {
 const location = useLocation();
 const navigate = useNavigate();
 const selectedReadingType = location.state?.selectedReadingType;


 // Redirect to Home if `selectedReadingType` is not set
 useEffect(() => {
   if (!selectedReadingType) navigate('/');
 }, [selectedReadingType, navigate]);

 const handleFinaliseReading = async () => {
  const user = await checkUserAuthentication();
  if (!user) {
    // Redirect or show login/signup modal
    navigate('/login', { state: { from: location.pathname } });
    return;
  }

  // User is authenticated, proceed with finalising reading
  const stepData = Object.keys(stepSelections).map((step) => ({
    step: parseInt(step, 10),
    ...stepSelections[step],
  }));

  try {
    const { error } = await supabase.from('finalised_readings').insert([
      {
        reading_type_id: selectedReadingType,
        step_data: stepData,
        user_id: user.id, // Use the authenticated user's ID
        email: user.email, // Use the authenticated user's email
      },
    ]);

    if (error) {
      console.error('Error finalising reading:', error);
    } else {
      navigate('/finalised'); // Navigate to a confirmation or finalised page
    }
  } catch (err) {
    console.error('Unexpected error:', err);
  }
};

 // State Management
 const [steps, setSteps] = useState([]);
 const [currentStep, setCurrentStep] = useState(0);
 const [selectedSuit, setSelectedSuit] = useState(null);
 const [selectedCard, setSelectedCard] = useState(null);
 const [notes, setNotes] = useState({});
 const [isMapOpen, setIsMapOpen] = useState(false);
 const [stepSelections, setStepSelections] = useState({});
 const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(true);
 const [readingQuestion, setReadingQuestion] = useState('');


 // Fetch Steps Data
 useEffect(() => {
   const fetchSteps = async () => {
     try {
       const { data, error } = await supabase
         .from('reading_type')
         .select('steps','name')
         .eq('id', selectedReadingType);


       if (error) console.error('Error fetching steps:', error);
       else if (data.length > 0) setSteps(data[0].steps || []);
     } catch (err) {
       console.error('Unexpected error:', err);
     }
   };


   fetchSteps();
 }, [selectedReadingType]);
 
 // Save Reading Question to Supabase
 const saveReadingQuestion = async () => {
  if (!readingQuestion.trim()) return; // Ensure the field is not empty

  try {
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      const { error } = await supabase.from('readings').insert([
        {
          user_id: user.id,
          reading_question: readingQuestion,
          reading_type: selectedReadingType,
        },
      ]);
      if (error) console.error('Error saving reading question:', error);
    }
  } catch (err) {
    console.error('Unexpected error:', err);
  }
};

const handleSaveQuestionAndCloseModal = async () => {
  if (!readingQuestion.trim()) {
    alert('Please enter a question before proceeding.');
    return;
  }

  await saveReadingQuestion();
  setIsQuestionModalOpen(false);
};




 const handleSuitSelection = (suitKey) => {
   setSelectedSuit(suitKey);
   setSelectedCard(null); // Reset card selection on suit change
 };


 const handleCardSelection = (event) => {
   const card = event.target.value;
   setSelectedCard(card);
 };
  const handleExit = () => {
   navigate('/'); // Redirect to the home page
 };




 const handleNoteChange = (event) => {
   setNotes((prev) => ({
     ...prev,
     [currentStep]: event.target.value,
   }));
 };


  const handleNextStep = async () => {
    if (!selectedSuit || !selectedCard) return;

    setStepSelections((prevSelections) => ({
      ...prevSelections,
      [currentStep]: {
        selectedSuit,
        selectedCard,
        notes: notes[currentStep] || '',
      },
    }));

    if (currentStep < steps.length - 1) {
      setCurrentStep((prevStep) => prevStep + 1);
    }

    const stepData = {
      step: currentStep + 1,
      selectedSuit,
      selectedCard,
      notes: notes[currentStep] || '',
    };

    try {
      const { error } = await supabase.from('readings').insert([
        {
          reading_type_name: selectedReadingType,
          step_data: stepData,
          user_notes: notes[currentStep] || '',
        },
      ]);
      if (error) console.error('Error saving step data:', error);
    } catch (err) {
      console.error('Unexpected error:', err);
    }

    setSelectedSuit(stepSelections[currentStep + 1]?.selectedSuit || null);
    setSelectedCard(stepSelections[currentStep + 1]?.selectedCard || null);
  };

  const handlePreviousStep = () => {
    if (currentStep > 0) {
      setStepSelections((prevSelections) => ({
        ...prevSelections,
        [currentStep]: {
          selectedSuit,
          selectedCard,
          notes: notes[currentStep] || '',
        },
      }));

      setCurrentStep((prevStep) => prevStep - 1);

      const prevSelections = stepSelections[currentStep - 1] || {};
      setSelectedSuit(prevSelections.selectedSuit || null);
      setSelectedCard(prevSelections.selectedCard || null);
    }
  };

  useEffect(() => {
    const currentSelections = stepSelections[currentStep] || {};
    setSelectedSuit(currentSelections.selectedSuit || null);
    setSelectedCard(currentSelections.selectedCard || null);
    setNotes((prevNotes) => ({
      ...prevNotes,
      [currentStep]: currentSelections.notes || '',
    }));
  }, [currentStep, stepSelections]); // Added stepSelections to the dependency array

 // Dynamic Content Based on State
 const { stepAction, stepMeaning, stepMap } = steps[currentStep] || {};
 const cardDetailsForSuit = selectedSuit ? cardDetails[selectedSuit].cards : [];
 const cardDetailsForSelectedCard = cardDetailsForSuit.find((card) => card.name === selectedCard);
    

 return (
  <div className="home-container p-10 max-w-md mx-auto">
     <h2 className="text-center text-l font-regular text-orange-900 mb-2">
       {selectedReadingType}</h2>
     <div className=" mt-0 font-normal relative">
     <h2 className="text-center text-l font-semibold text-slate-700 mb-6"> Step {currentStep + 1}</h2></div>

     {/*} Sticky Tab for Question */}

  <button
    onClick={() => setIsQuestionModalOpen(true)}
   className="fixed top-24 left-0 p-3 rounded-lg bg-orange-800/5 shadow-lg focus:ring-0 focus:ring-amber-100"
    aria-label="Reading question">
    <img src="readingQicon.svg" alt="Question Tab Icon" className="w-6 h-6" />
  </button>

{/* Reading Question Modal */}

<Dialog open={isQuestionModalOpen} onClose={() => {}}>
  <div className="fixed inset-0 bg-gray-500/30 flex items-center justify-center z-10">
    <div className="bg-white shadow-sm sm:rounded-lg">
      <div className="px-12 py-5 sm:p-10">
        <h3 className="text-base font-semibold text-neutral-900">Reading focus</h3>
        <div className="mt-2 max-w-md text-sm text-neutral-500">
          <p>
          Before you begin, clarify a question for this reading.
          </p>
          </div>
    <div>
            <textarea
               value={readingQuestion}
               onChange={(e) => setReadingQuestion(e.target.value)}
               placeholder="Record the question here"
               className=" border-b-neutral-200 border-transparent ocus:border-orange-500 focus:ring-orange-900/10 focus:ring-1 rounded-md focus:border-transparent w-full mt-8 resize-none text-base text-neutral-900 placeholder:text-gray-400 sm:text-sm/6"
              defaultValue={''}
              rows={3}            />
        </div>
         <button
                onClick={handleSaveQuestionAndCloseModal}
                className="rounded-full bg-neutral-700 px-3 py-2 text-sm mt-6 font-semibold text-white shadow-xs hover:bg-orange-900/80   focus-visible:outline-orange-900"
              >
                Close
            </button>
          </div>
        </div>
      </div>
    </Dialog>
    


     {/* Step Details */}
     <div className="text-left text-neutral-500 mb-6">
       <p className="text-sm"> Action: {stepAction || 'Step not available'}</p></div>
      <div><p className="text-neutral-700 text-sm mb-10"> Meaning: {stepMeaning || ''}</p>
       </div>
       

     {/* Suit Selection */}
     <div className="font-normal relative">
       <p className="text-sm font-semibold mb-4">Card suit drawn:</p> 
        <div className="mt-4 mb-4 grid grid-cols-5 gap-5">
         {Object.keys(cardDetails).map((suitKey) => {
           const { name, icon } = cardDetails[suitKey];
           return (
             <button
               key={suitKey}
               onClick={() => handleSuitSelection(suitKey)}
               className={`suit-icon p-3 rounded-lg ${selectedSuit === suitKey ? 'bg-orange-900/10' : 'bg-gray-100'}`}
               aria-label={`Select ${name}`}
             >
               <img src={icon} alt={`${name} icon`} className="flex w-full items-center max-h-8 max-w-8 mx-auto" />
             </button>
           );
         })}
       </div>
     </div>

{/* Card Selection */}

     {selectedSuit && (
       <Select
         value={selectedCard || ''}
         onChange={handleCardSelection}
         className="font-sm w-full mb-10 p-3 border-neutral-300 rounded-md ring-1 ring-neutral-100 focus:ring-orange-900/30 hover:bg-orange-900/5 focus:border-none">
         <option value="" disabled>
           Select a card
         </option>
         {cardDetailsForSuit.map((card) => (
           <option key={card.name} value={card.name}>
             {card.name}
           </option>
         ))}
       </Select>
     )}


      {/* Card Details */}
      {selectedCard && cardDetailsForSelectedCard && (
       <div className="font-normal relative">
         <p className="text-sm font-semibold">You selected:</p>
         <div className="card-details font-regular text-sm mt-4 mb-10 p-10 border border-gray-300 shadow-lg rounded-md relative">
          
           {/* Top-left and Top-right Suit Icons */}
           <div className="absolute top-6 left-8 flex flex-col items-center">
             <img src={cardDetails[selectedSuit].icon} alt={`${selectedSuit} icon`} className="w-10 h-10 m-4" />
           </div>
           <div className="absolute top-6 right-8 flex flex-col items-center">
             <img src={cardDetails[selectedSuit].icon} alt={`${selectedSuit} icon`} className="w-10 h-10 m-4" />
           </div>


           {/* Center Display for Court, Numbered Cards, and Major Arcana */}
           <div className="flex justify-center items-center h-40 mt-6">
             {["King", "Queen", "Knight", "Page"].includes(selectedCard) ? (
               <img src={courtCardImages[selectedCard]} alt={`${selectedCard} icon`} className="w-40 h-40 mt-8"/>
             ) : (
               <span className="text-5xl text-amber-700 font-bold text-center">
                 {numberMapping[selectedCard] || cardDetailsForSelectedCard.name}
               </span>
             )}
           </div>


           {/* Card Name and Description */}
           <p className="text-lg text-gray-800 font-semibold mt-6 text-center">
             {cardDetailsForSelectedCard.name} of {cardDetails[selectedSuit].name}
           </p>
           <p className="text-gray-600 mt-2">{cardDetailsForSelectedCard.meaning}</p>
           <p className="text-gray-500 italic mt-2">Reverse: {cardDetailsForSelectedCard.reverse}</p>
         </div>
       </div>
     )}


     {/* Note-Taking Section */}
     <textarea
       value={notes[currentStep] || ''}
       onChange={handleNoteChange}
       placeholder="Add notes here"
       className="font-sm w-full mb-6 p-3 border-neutral-300 rounded-md ring-1 ring-neutral-100 focus:ring-orange-900/30 hover:bg-orange-900/5 focus:border-none"
     />


 {/* Navigation Buttons */}
<div className="navigation-buttons flex justify-between mb-10">
  {/* Previous Button */}
  <button
    onClick={handlePreviousStep}
    disabled={currentStep === 0}
    className={`p-2 w-1/2 mr-2 ${
      currentStep === 0
        ? 'rounded-full bg-white text-slate-400 ring-1 ring-slate-200 cursor-not-allowed'
        : 'rounded-full bg-white text-md font-regular text-orange-900 shadow-sm ring-1 ring-inset ring-orange-900/30 hover:bg-orange-900/5'
    }`}
  >
    Previous
  </button>

{/* Next/Finalise Button */}
<button
  onClick={currentStep === steps.length - 1 ? handleFinaliseReading : handleNextStep}
  disabled={!selectedSuit || !selectedCard}
  className={`p-2 rounded w-1/2 ml-0 ${
    !selectedSuit || !selectedCard
      ? 'rounded-full bg-white text-slate-400 ring-1 ring-slate-200 cursor-not-allowed'
      : 'rounded-full bg-white text-md font-regular text-orange-900 shadow-sm ring-1 ring-inset ring-orange-900/30 hover:bg-orange-900/5'
  }`}
>
  {currentStep === steps.length - 1 ? 'Finalise Reading' : 'Next'}
</button>
</div>

{/* Exit Button */}
<button
       onClick={handleExit}
       className="fixed top-1 right-1 p-2 rounded-md bg-white-50  focus:outline-none"
       aria-label="Exit Reading Session"
     >
       <svg
         xmlns="http://www.w3.org/2000/svg"
         fill="none"
         viewBox="0 0 24 24"
         strokeWidth={1.5}
         stroke="currentColor"
         className="h-4 w-4 text-neutral-400"
       >
         <path
           strokeLinecap="round"
           strokeLinejoin="round"
           d="m9 9 6-6m0 0 6 6m-6-6v12a6 6 0 0 1-12 0v-3"
         />
       </svg>
     </button>


     {/* Sticky Map Icon */}
     <button
       onClick={() => setIsMapOpen(true)}
       className="fixed top-6 left-0 p-3 rounded-lg bg-orange-800/5 shadow-lg focus:ring-0 focus:ring-amber-100"
       aria-label="Open Step Map"
     >
       <img src="map-icon.svg" alt="Map Icon" className="w-6 h-6" />
     </button>


     {/* Map Panel */}
     <Dialog open={isMapOpen} onClose={() => setIsMapOpen(false)} className="relative z-10">
       {/* Backdrop */}
       <div className="fixed inset-0 bg-gray-500/30 transition-opacity" aria-hidden="true" />
       
       {/* Centered Panel */}
       <div className="fixed inset-0 flex items-center justify-center">
         <Dialog.Panel className="relative w-11/12 max-w-lg bg-white shadow-xl rounded-lg">
           {/* Panel Header */}
           <div className="flex items-center justify-between p-6">
             <Dialog.Title className="text-lg font-medium text-gray-700">
               Step {currentStep + 1} Position
             </Dialog.Title>
             <button
               onClick={() => setIsMapOpen(false)}
               className="rounded-md text-gray-400 hover:text-gray-500  focus:ring-orange-900/10 focus:outline-none"
               aria-label="Close panel"
             >
               <XMarkIcon className="h-6 w-6" />
             </button>
           {/* Panel Content */}
           <div className="relative px-4 py-6">
             <img src={[stepMap]}
               alt={`Map for Step ${currentStep +1}`}
               className="w-full h-auto rounded-md"
             />
             </div>
             </div>
           </Dialog.Panel>
         </div>
         </Dialog>
         </div>
 );
  };

export default ReadingSession;