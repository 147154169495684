import CupsIcon from './assets/cups.svg';
import PentaclesIcon from './assets/pentacles.svg';
import SwordsIcon from './assets/swords.svg';
import RodsIcon from './assets/rods.svg';
import MajorArcanaIcon from './assets/majorArcana.svg';



const rods = {
 name: "Wands",
 icon: RodsIcon, // Update this with the correct icon path
 cards: [
   { name: "King", meaning: "Of noble character - honest, loyal, conscientious, and just. Generally married, or partered, often a country-dweller.", reverse: "Unyielding in judgments; strict but tolerant. Advice should be considered." },
   { name: "Queen", meaning: "Warm and honourable, devoted to their family and loyal to their friends. May also signify some success in business.", reverse: "Practical and careful with money, obliging. Can mean jealousy, opposition, deceit, or infidelity if nearby cards tend toward similar meanings." },
   { name: "Knight", meaning: "A young person on the go, attractive to peers, capable of creating dissension in their wake. May indicate change of residence, travel, or departure.", reverse: "Conflict, division in emotional or business life."},
   { name: "Page", meaning: "A young bearer of messages, an envoy, faithful lover. May represent family intelligence.", reverse: "Bad news, indecision, instability."},
   { name: "Ten", meaning: "A sense of oppression coupled with some kind of success; guilt feelings, doubt.", reverse: "Conflict of opposites, difficulties, intrigue." },
   { name: "Nine", meaning: "Inner strength crystallised by opposition.", reverse: "Distress, obstacles, adversity." },
   { name: "Eight", meaning: "Momentum toward a desired end. Great expectations, haste. May also denote the arrows of love.", reverse: "Guilt, jealousy, internal or domestic disputes." },
   { name: "Seven", meaning: "Man outnumbered by adversaries but ultimately victorious. Courage and persistence create advantage in business, trade, or verbal war.", reverse: "Anxiety, confusion, chagrin. Avoid indecision." },
   { name: "Six", meaning: "Triumphal reconciliation of desire and reality. Great news fulfilling hopes.", reverse: "Sense of vulnerability and fear of enemy at the gates. Betrayal, as to the enemy. Indefinite delay." },
   { name: "Five", meaning: "Strenuous competition for material gains and recognition; business affairs may improve.", reverse: "Legal complications, trickery." },
   { name: "Four", meaning: "Material and emotional blessings - harmony, repose, prosperity, the perfection of one's affairs.", reverse: "The meaning is the same; prosperity, peace, abundance." },
   { name: "Three", meaning: "Fruitful collaboration in business; also signifies enterprise, ingenuity, effort, and commerce.", reverse: "Help offered not reliable; adversity ceases." },
   { name: "Two", meaning: "Though one holds the world in the palm of his hand in terms of wealth, dominion, magnificence, sadness is still indicated.", reverse: "Sense of wonder, enchantment, possible trouble, fear." },
   { name: "Ace", meaning: "A time of beginnings - a relationship, birth, enterprise, creation or, in a sense, the creative or emotional momentum behind them.", reverse: "A shallow existence, decadence, spiritual ruin." },
 ],
};


const cups = {
 name: "Cups",
 icon: CupsIcon,
 cards: [
   { name: "King", meaning: "Represents an individual of business, law, or divinity who is responsible, generous, and considerate of others.", reverse: "A selfish, deceitful person. Can indicate considerable loss."},
   { name: "Queen", meaning: "A beautiful, loving human, intuitive and practical. Symbolises a happy marriage or partnering and vision.", reverse: "May be a good human, but may be perverse and not to be trusted." },
   { name: "Knight", meaning: "An intelligent individual of high value, bearer of important messages. Represents advances, invitation, or change to new endeavours.", reverse: "A seducer, unworthy of trust. Examine all propositions."},
   { name: "Page", meaning: "A thoughtful, artistic youth, willing to help the seeker. Can also signify the birth of a child or new ideas in business.", reverse: "Obstacles, responsibility not assumed." },
   { name: "Ten", meaning: "Perfection of human love and friendship, contentment; attainment of the heart's desires.", reverse: "Loss of harmony, anger, feelings of guilt." },
   { name: "Nine", meaning: "Material, physical, and spiritual well-being. Happiness.", reverse: "Overemphasis on the physical and material side of one's being; imperfections." },
   { name: "Eight", meaning: "A withdrawal from the present situation; decline of a matter of little consequence for good or evil.", reverse: "Material aspect chosen over spiritual; joy, feasting." },
   { name: "Seven", meaning: "The seeker has a strong, active imagination and many desires but lacks tenacity to translate ideas into reality. Limited attainment.", reverse: "Determination, tenacity; realization of a project." },
   { name: "Six", meaning: "Reflection on the past, things that have vanished. May indicate desire for change.", reverse: "Inability to outgrow the past creates failure. Possible gift from the past." },
   { name: "Five", meaning: "Indicates some loss in the process of receiving something, i.e., a disappointing inheritance, possibly a marriage carrying bitterness and frustration.", reverse: "Hope renewed, return of a friend or relative, a new alliance." },
   { name: "Four", meaning: "Sense of boredom and dissatisfaction with environment and possibly self; longing for change. Action is needed, but seeker is hesitant.", reverse: "Good omen for something new and possibly entering Querent's life." },
   { name: "Three", meaning: "Bountiful outcome in perfection and merriment; fulfilment, healing.", reverse: "Physical enjoyment and the pleasures of the senses to excess." },
   { name: "Two", meaning: "Love, affinity, friendship; a spiritual union, harmony.", reverse: "Disharmony, jealousy, false love." },
   { name: "Ace", meaning: "Wealthy in the things of the spirit - love, joy, fertility, content.", reverse: "Revolution, bankruptcy, change." },
 ],
};


const pentacles = {
 name: "Pentacles",
 icon: PentaclesIcon,
 cards: [
   { name: "King", meaning: "A dark individual of good intelligence and strong convictions who is a considerate and affectionate partner.", reverse: "Corrupt. Tendency toward drunkenness, unfaithfulness, and perverse use of ability." },
   { name: "Queen", meaning: "A dark human of generous and perceptive spirit who is a devoted partner; signifies magnificence, opulence, security.", reverse: "Suspicious, distrustful, anxious." },
   { name: "Knight", meaning: "A responsible, patient, hard-working human. Also denotes usefulness, ability to serve.", reverse: "Idleness, carelessness, lethargy." },
   { name: "Page", meaning: "A young person; serious, scholarly, hardworking. May denote scholarship, respect for new ideas.", reverse: "Rebel, dissipation, prodigality. Unfavourable news." },
   { name: "Ten", meaning: "Pertains to family matters such as inheritance, lineage, or gains.", reverse: "Loss of family reputation or inheritance. Be careful of financial dealings." },
   { name: "Nine", meaning: "Financial security, accomplishment, certainty.", reverse: "Deception, voided project, bad faith." },
   { name: "Eight", meaning: "Employment, skilled work, commission.", reverse: "Failure, conceit, greedy desire for wealth; lending money at exorbitant rates." },
   { name: "Seven", meaning: "Success in matters relating to money, business, barter.", reverse: "Apprehension over money matters; impatience." },
   { name: "Six", meaning: "Business success combined with a generous and fair sharing of proceeds; philanthropy.", reverse: "Greedy desire for wealth; illusion, envy." },
   { name: "Five", meaning: "Material or spiritual impoverishment; loneliness.", reverse: "Dissonance in marriage; obstacles to resolution of personal relations." },
   { name: "Four", meaning: "Denotes one possessive of material things, impressed with the power such possession wields. May also mean inheritance, gift, legacy.", reverse: "Material ambitions thwarted, possible loss of possessions." },
   { name: "Three", meaning: "Card of mastery, such as of a skill, nobility, renown, glory.", reverse: "Mediocrity in work or person; immaturity, pettiness." },
   { name: "Two", meaning: "Cause for jubilation; troubles not as serious as had been imagined.", reverse: "Conflict of irreconcilable opposites; simulated enjoyment and letters of exchange." },
   { name: "Ace", meaning: "Complete contentment, bliss, ecstasy, quick intelligence; gold, wealth.", reverse: "Deterioration of character through wrong attitude toward wealth; the evil side of wealth." },
 ],
};


const swords = {
 name: "Swords",
 icon: SwordsIcon,
 cards: [
     { name: "King", meaning: "An authority in medicine, law, or government; brilliant and wise.", reverse: "Brilliance coupled with cruelty; evil intention." },
     { name: "Queen", meaning: "A saddened individual representing loss, privation, absence, sterility, or separation.", reverse: "A superficial human dominated by negative emotions, malice, intolerance, pettiness." },
     { name: "Knight", meaning: "Action-oriented, of military nature epitomising bravery, skill, and determination. Depending on nearby cards, may mean conflict, war, opposition, or destruction.", reverse: "Indiscreet, extravagant, foolish." },
     { name: "Page", meaning: "Denotes watchfulness, spying, examination for either positive or negative reasons.", reverse: "Unforeseen danger; unprepared state; illness a possibility." },
     { name: "Ten", meaning: "A card of trouble and suffering, ruin, pain, affliction, tears. Not a card of violent death.", reverse: "A period of advantage, profit, power." },
     { name: "Nine", meaning: "Vacillation on an important matter, failure, disappointment, desolation. May mean miscarriage or death of a loved one.", reverse: "Shame, suspicion, uncertainty, imprisonment." },
     { name: "Eight", meaning: "Inability to extricate oneself from a difficult situation; conflict, crisis, slander.", reverse: "Anxiety, deception, opposition; sometimes unforeseen tragedy." },
     { name: "Seven", meaning: "Plans, hopes, expectations; irritations and possible failure in relation to the same.", reverse: "Counsel, instruction, sound advice." },
     { name: "Six", meaning: "Journey by water; action toward resolution of difficulties, perhaps through another person.", reverse: "Dilemma without any immediate solution." },
     { name: "Five", meaning: "Humiliation, dishonour, loss.", reverse: "A cause for mourning." },
     { name: "Four", meaning: "Retreat, recuperation, exile.", reverse: "Activity tempered with economy and careful administration." },
     { name: "Three", meaning: "Rupture, separation, absence, delay.", reverse: "Disharmony, alienation, confusion, disorder." },
     { name: "Two", meaning: "Peaceful hiatus in an otherwise discordant situation.", reverse: "End of stalemate; falsehood, disloyalty." },
     { name: "Ace", meaning: "Triumph of great force, either love or hatred; may signify a birth of special significance.", reverse: "Extremes of love, hate, or ambition can lead to disastrous results." }
 ],
};


const majorArcana = {
 name: "Major Arcana",
 icon: MajorArcanaIcon, // Update this with the correct icon path
 cards: [
  { name: "Judgment", meaning: "Growing awareness; shift in personal consciousness toward union with the universal.", reverse: "Physical health threatened, disillusionment, separation, loss of worldly goods." },
  { name: "Hermit", meaning: "Withdrawal; meditation on meaning of life; predicts a meeting with one who will counsel or inspire the seeker.", reverse: "Inability or refusal to mature, to deepen wisdom with age." },
  { name: "Wheel of Fortune", meaning: "The ups and downs of life, constant change. One must find the still centre for tranquillity.", reverse: "The quality of your involvement will be the measure of your reward." },
  { name: "Justice", meaning: "Balance, sense of proportion in self and life; accountability for actions.", reverse: "Predicts lawlessness, injustice, lack of balance. Use mercy rather than severity if near Hanged Man." },
  { name: "Hanged Man", meaning: "Die to the social order to dispense justice or continue growth. Spirituality, intuition, self-sacrifice.", reverse: "Concentration on oneself; egoist; opposed to spiritual influence." },
  { name: "Death", meaning: "Death, rebirth, generally in areas of consciousness. Great change in life.", reverse: "Stagnation, tendency to lethargy." },
  { name: "Temperance", meaning: "Mature adaptation to life; individualised existence.", reverse: "Conflicting interests in business or personal life; unfortunate combinations." },
  { name: "Devil", meaning: "Lack of essential humanity; emphasis on material and carnal to detriment of others. Destructive force.", reverse: "Possibility of spiritual understanding; tendency to vacillation, pettiness." },
  { name: "Tower", meaning: "", reverse: "" },
  { name: "", meaning: "Unforeseen catastrophe, disruption of one's style of life or way of thinking which may be followed by enlightenment.", reverse: "Tyranny, threats to one's freedom. The same as above to a lesser degree." },
  { name: "Star", meaning: "A glimpse of a transcendent way of life. Good health.", reverse: "Illness, pessimism, haughtiness." },
  { name: "Moon", meaning: "A yearning for fulfilment. Unforeseen perils; threatening situations, possibly to loved ones. Psychic forces.", reverse: "Peace will come after a difficult period." },
  { name: "Sun", meaning: "Contentment, liberation, attainment of personal or business goals.", reverse: "Uncertainty of future plans; loss of something of value." },
  { name: "Strength", meaning: "Courage, magnanimity, persistence, patience, spiritual power. Able to offset any bad luck in surrounding cards.", reverse: "Weakness, possible loss of honour, discord." },
  { name: "The Fool", meaning: "Denotes the positive inner forces which influence an individual in his choices.", reverse: "The choice made is likely to be foolish." },
  { name: "World", meaning: "Reward, completion, cosmic consciousness; also change, travel.", reverse: "Cramped vision; fear of change; stagnation." },
  { name: "The Magician", meaning: "Ability to translate ideas into action, use psychic powers to advantage; skill, diplomacy, self-confidence.", reverse: "The use of power for destructive ends. Bad luck." },
  { name: "High Priestess", meaning: "Denotes the feminine principle of love and relatedness; the ideal partner and mother, supportive of loved ones; mystery, wisdom, unrevealed influences at work.", reverse: "Indicates superficial qualities, physical passion divorced from love." },
  { name: "Empress", meaning: "Fertility in body or mind, initiative, action, bountiful harvest. Good luck.", reverse: "Sterility, wastefulness of resources, indecision, disruption of life at home or in society." },
  { name: "Emperor", meaning: "Authority. One who is master of their emotions; active intelligence able to realise ideas.", reverse: "Immaturity. Tendency to be gullible. Self-control is limited." },
  { name: "Hierophant", meaning: "One who leads away from worldly concerns. Possibility of romantic partnership or alliance of some kind.", reverse: "Unorthodoxy, openness to new ideas, unconventionality." },
  { name: "Lovers", meaning: "Conflict between different attractions and the necessity of choice which brings responsibility. Beauty, inner harmony.", reverse: "Quarrels, frustration of romance or partnership; failure." },
  { name: "Chariot", meaning: "Control over tension of opposites. Triumph over difficulties through perseverance; a balanced life; success.", reverse: "Failure in carrying out a project, riot, litigation." }
  ]
  };
  
// Export all suits and Major Arcana as an object
const cardDetails = {
 rods,
 cups,
 pentacles,
 swords,
 majorArcana,
};


export default cardDetails;

